import styled from "styled-components";
import { Section } from "./Scroll";
import { MobileView, DesktopView } from '../Pages/Design';
export const TitleContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 1600px) {
    margin-right: 10rem;
  }
  @media screen and (max-width: 1599px) {
    margin-right: 5rem;
  }
  @media screen and (max-width: 1000px) {
    margin-right: 0rem;
  }
  @media screen and (min-width: 768px) {
    max-width: 200px;
  }
  @media screen and (max-width: 767px) {
    margin: 5rem 0 4rem 0;
  }
  .responsive {
    writing-mode: vertical-rl;
    margin: 0;
    text-align: center;
  }
`;
const TextContainer = styled.div`
  max-width: 780px;
  display: flex;
  line-height: 34px;
  font-size: 24px;
  @media screen and (max-width: 767px) {
    .text {
      font-size: 15px;
      padding: 0 1rem;
      margin-bottom: 2rem;
    }
  }
`;
export const NumberContainer = styled.div`
  line-height: 40px;
  text-align: center;
  margin-bottom: 36px;
  letter-spacing: 1px;
`;
const Img = styled.div`
  @media screen and (max-width: 767px) {
    width: 160px;
    padding: 0 1rem;
  }
  width: 320px;
`;
const About = () => {
  return (<div class="w-100">
    <MobileView>
      <div
        id="about"
        class="flex-column d-inline-block w-100 d-flex justify-content-center"
        style={{ margin: '0 0 15rem 0', paddingTop: '75px' }}
      >
        <div class="row d-flex align-items-end">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div>
              <p style={{ lineHeight: '32px', textAlign: 'center', letterSpacing: '1px', marginBottom: '32px' }}>
                01
              <br />
              ABOUT
            </p>
            </div>
            <div style={{ writingMode: 'vertical-rl' }}>
              <h2 className="responsive" style={{ fontSize: '38px', letterSpacing: '4px' }}>萊盛的宗旨</h2>
            </div>
          </div>

          <div class="col">
            <TextContainer>
              <p class="text" style={{ lineHeight: '21.72px', letterSpacing: '2px', fontSize: '15px', marginTop: '60px' }}>
                Lyson崇尚〔自由&舒活〕宗旨。
                <br />
                <br />
              以力學緻密的結構計算，營造出六邊形的立體空間，有效率地將設計與資通融合；同時注重綠能與節能的優化，將設計融入生活，將生活掌在手中，進而達成雙向互惠的關係。
            </p>
            </TextContainer>

            <img
              src="images/triple.png"
              alt="triple"
              style={{ width: "155px", marginLeft: '16px' }}
            />

          </div>
        </div>
        <div style={{ paddingRight: '24px', marginTop: '55px', width: '100%', textAlign: 'right', lineHeight: '80px', borderRight: '2px solid black' }}>
          ABOUT
          </div>
      </div>
    </MobileView>
    <DesktopView>
      <div
        id="about_d"
        class="flex-column d-flex w-100 justify-content-center align-items-start"
        style={{ padding: '15rem 0 15rem 0', maxWidth: '1420px' }}
      >
        <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <NumberContainer>
              <p>
                01
              <br />
              ABOUT
            </p>
            </NumberContainer>
            <h2 className="responsive" style={{ writingMode: 'vertical-lr', fontWeight: '900', letterSpacing: '8px', lineHeight: '84px' }}>
              萊盛的宗旨
          </h2>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginLeft: '265px' }}>
            <TextContainer>
              <p class="text" style={{ lineHeight: '34.75px', fontWeight: '700', fontSize: '24px', letterSpacing: '1px' }}>
                Lyson崇尚〔自由&舒活〕宗旨。
              <br />
                <br />
              以力學緻密的結構計算，營造出六邊形的立體空間，有效率地將設計與資通融合；同時注重綠能與節能的優化，將設計融入生活，將生活掌在手中，進而達成雙向互惠的關係。
            </p>
            </TextContainer>
            <Img>
              <img
                src="images/triple.png"
                alt="triple"
                style={{ width: "100%", marginTop: '32px' }}
              />
            </Img>
          </div>
        </div>
        <Section>ABOUT</Section>
      </div>
    </DesktopView>
  </div>);
};
export default About;
