import styled from "styled-components";
const TitleWrap = styled.div`
  width: 100%;
  @media screen and (min-width: 1400px) {
    padding-left: 20rem;
  }
  @media screen and (min-width: 1000px) {
    padding-left: 4rem;
  }
`;
const Hover = styled.div`
@media screen and (max-width: 767px) {
  display: none;
}
  width: 0;
  opacity: 0;
  transition: width 0.1s;
`;
const Button = styled.button`
  height: 106px;
  align-items: center;
  letter-spacing: 3px;
  border: none;
  background: #221e1f;
  border-radius: 50px;
  display: flex;
  flex-wrap: flex;
  padding: 0 120px;
  font-size: 18px;
  &:hover {
    background-color: #fff;
    border: 2px solid #f15a28;
  }
  &:hover ${Hover} {
    width: 160px;
    opacity: 100%;
    margin-left: 20px;
  }
  .a {
    width: 100%;
    margin: 0;
    padding: 0;
    color: #f15a28;
  }
`;
const ButtonM = styled.button`
  align-items: center;
  letter-spacing: 3px;
  border: none;
  background: #221e1f;
  border-radius: 50px;
  flex-wrap: flex;
  padding: 32px 48px;
  width: 100%;
  .a {
    width: 100%;
    font-size: 16px;
    color: #f15a28;
  }
`;
const DesktopView = styled.div`
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
const MobileView = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
const Kanbellab = () => {
  return (
    <>
      <DesktopView>
        <TitleWrap>
          <div class="w-100 vh-100 d-inline-block p-5 d-flex justify-content-center" style={{ alignItems: 'center' }}>
            <div class="pr-5" style={{ position: 'relative', paddingRight: '32rem', paddingBottom: '10rem' }}>
              <img src="/images/logo_kabellab.png" alt="kabellab_logo" style={{ width: '100%', marginBottom: '4rem', maxWidth: '404px' }} />
              <h2 class="responsive" style={{ fontSize: "38px", fontWeight: '900' }}>
                來自汶萊，
              <br />
              宗旨是生產簡易且美好的產品。
            </h2>
              <p style={{ lineHeight: "26px", marginTop: '2.875rem' }}>
                以簡單安裝、且容易上手的方式設計產品，同時又不失產品本身的質量。
            </p>
            </div>
            <DesktopView >
              <img src="/images/bgimage_ports.png" alt="ports" style={{ maxWidth: '430px', position: 'absolute', right: '0', width: '30%', top: '40%' }} />
            </DesktopView>
          </div>
        </TitleWrap>
        <div class="kaballab" style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src="/images/simple_nice.png" alt="title" style={{ width: '40%' }} />
          <img src="/images/kaballab.png" alt="li" style={{ width: '40%' }} />
          <img src="/images/labcooper.png" alt="cooper" style={{ width: '90%', maxWidth: '1400px' }} />
          <img src="/images/labpanel.png" alt="pannel" style={{ width: '90%', maxWidth: '1400px' }} />
          <img src="/images/labpatch.png" alt="patch" style={{ width: '90%', maxWidth: '1400px' }} />
          <img src="/images/labplug.png" alt="plug" style={{ width: '90%', maxWidth: '1400px' }} />
          <img src="/images/labport.png" alt="port" style={{ width: '90%', maxWidth: '1400px' }} />
        </div>
      </DesktopView>

      <MobileView>
        <div class="w-100 d-inline-block d-flex justify-content-center" style={{ alignItems: 'center', height: '500px' }}>
          <div class="pr-5" style={{ position: 'relative' }}>
            <img src="/images/logo_kabellab.png" alt="kabellab_logo" style={{ width: '272px', marginBottom: '1rem', maxWidth: '404px' }} />
            <p class="mt-md-5" style={{ lineHeight: "26px", letterSpacing: '1px', padding: '15px' }}>
              來自汶萊，<br />宗旨是生產簡易且美好的產品。
            </p>
          </div>
        </div>
      </MobileView>
      <MobileView>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '1rem', alignItems: 'center' }}>
          <img src="/images/simple_nice.png" alt="title" style={{ width: '235px', marginBottom: '160px', paddingTop: '68px' }} />
          <img src="/images/kaballab.png" alt="li" style={{ width: '80%', marginBottom: '160px' }} />
          <img src="/images/labcooper_m.png" alt="cooper" style={{ width: '80%', marginBottom: '60px' }} />
          <img src="/images/labpanel_m.png" alt="pannel" style={{ width: '80%', marginBottom: '60px' }} />
          <img src="/images/labpatch_m.png" alt="patch" style={{ width: '80%', marginBottom: '60px' }} />
          <img src="/images/labplug_m.png" alt="plug" style={{ width: '80%', marginBottom: '60px' }} />
          <img src="/images/labport_m.png" alt="port" style={{ width: '80%', marginBottom: '60px' }} />
        </div>
        <div style={{ margin: "4rem 33px 3rem" }}>
          <ButtonM>
            <a href="http://www.kabellab.com" target="_blank" rel="noreferrer" className="a" style={{ fontSize: '15px', letterSpacing: '1px' }}>
              到Kabellab官方網站看更多
          </a>
          </ButtonM>
          <p style={{ color: "#D67171", marginTop: '12px', width: '100%', textAlign: 'center', fontSize: '12px', letterSpacing: '2px', lineHeight: '17.38px' }}>※若有產品相關疑問，歡迎聯絡萊盛。</p>
        </div>
      </MobileView>
      <DesktopView>
        <div style={{ margin: "200px 1rem 167px" }}>
          <Button>
            <a href="http://www.kabellab.com" target="_blank" rel="noreferrer" className="a">
              到Kabellab官方網站看更多
          </a>
            <Hover>
              <img src="images/hover.png" alt="line" />
            </Hover>
          </Button>
          <p style={{ color: "#D67171", marginTop: '2rem', width: '100%', textAlign: 'center' }}>※若有產品相關疑問，歡迎聯絡萊盛。</p>
        </div>
      </DesktopView>
    </>
  );
};
export default Kanbellab;
