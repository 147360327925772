import "./App.css";
import { Routes, Route } from "react-router-dom";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Home from "./Pages/Home";
import Tech from "./Pages/Tech";
import Design from "./Pages/Design";
import Kanbellab from "./Pages/Kanbellab";
import styled from "styled-components";
import Top from "./component/Top";
const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
function App() {
  return (
    <Wrap id="home">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services/design" element={<Design />} />
        <Route path="/services/tech" element={<Tech />} />
        <Route path="/kanbellab" element={<Kanbellab />} />
      </Routes>
      <Top />
      <Footer />
    </Wrap>
  );
}

export default App;
