import { useState } from 'react';
import { TitleContainer, NumberContainer } from "./About";
import styled from "styled-components";
const MobileView = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
const DesktopView = styled.div`
  @media screen and (max-width: 767px) {
    display: none;
  }
  @media screen and (min-width: 768px) {
  width:100%;
  display:flex;
  justify-content: center;
  }
`;
const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [context, setContext] = useState('');
  var body = "" + context + '%0A%0A%0A';//%0A是換行 換了三行
  body += "From：" + name + '%0A';
  body += "Email：" + email + '%0A';
  body += "Tel：" + tel;
  return (
    <div id="CONTACT" class="flex-column d-inline-block w-100 pl-md-5 d-flex justify-content-center mb-md-5 mt-md-5" >
      <div class="d-md-flex align-items-start">
        <MobileView>
          <p style={{ lineHeight: '32px', textAlign: 'center', letterSpacing: '1px', marginBottom: '32px', paddingTop: '90px' }}>
            02 <br /> CONTACT
            </p>
          <div style={{ writingMode: 'vertical-rl', width: '100%', display: 'flex', alignItems: 'center', marginBottom: '58px' }}>
            <h2 className="responsive" style={{ fontSize: '38px', letterSpacing: '8px', lineHeight: '48px' }}>聯絡我們，<br />馬上詢問。</h2>
          </div>
          <form
            enctype="text/plain"
            accept-charset="utf-8"
            method="GET"
            class="col col-md-8 h-100 d-flex flex-column align-items-end"
            style={{ paddingTop: "10px", maxWidth: "650px", width: '100%' }}
          >
            <div class="field w-100 pb-3 justify-content-between">
              <span class="mr-md-1 w-100">姓名</span>
              <input id="nameText" class="w-100" type="text" name="name" style={{ marginTop: '6px' }} value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div class="field w-100 pb-3 justify-content-between">
              <span class="mr-md-1 w-100">電話</span>
              <input id="telText" class="w-100" type="tel" style={{ marginTop: '6px' }} value={tel} onChange={(e) => setTel(e.target.value)} />
            </div>
            <div class="field w-100 pb-3 justify-content-between">
              <span class="mr-md-1 w-100">電子信箱</span>
              <input id="emailText" class="w-100" type="email" style={{ marginTop: '6px' }} value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div class="field w-100 justify-content-between" style={{ marginBottom: '36px' }}>
              <span class="mr-md-1 w-100">詢問內容</span>
              <textarea id="bodyText" class="w-100" name="comments" rows="10" cols="54" style={{ marginTop: '6px' }} value={context} onChange={e => setContext(e.target.value)}></textarea>
            </div>
            <a class="submit" href={`mailto:lyson@lyson.cc?subject=聯絡萊盛&body=${body}`} style={{ width: '100%', marginBottom: '32px', height: '50px', lineHeight: '50px', textAlign: 'center' }}>確認並送出</a>
          </form>
        </MobileView>
        <DesktopView>
          <div style={{ width: '1420px', display: 'flex', justifyContent: 'space-between', marginBottom: '177px' }}>
            <TitleContainer>
              <NumberContainer>
                <p>02<br />CONTACT</p>
              </NumberContainer>
              <h2 className="responsive" style={{ writingMode: 'vertical-lr', paddingBottom: '10rem', fontWeight: '900', letterSpacing: '8px', lineHeight: '84px' }}>聯絡我們，<br />馬上詢問。</h2>
            </TitleContainer>
            <form
              action="mailto:lyson@lyson.cc"
              method="GET"
              enctype="text/plain"
              class="flex-column align-items-end"
              style={{ paddingTop: "120px", maxWidth: "650px", width: '100%', display: 'flex', justifyContent: 'center' }}
            >
              <div class="field w-100 pb-5 align-items-center d-flex justify-content-between">
                <span class="mr-md-1">姓名</span>
                <input id="nameText" type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} />
              </div>
              <div class="field w-100 pb-5 align-items-center d-flex justify-content-between">
                <span class="mr-md-1">電話</span>
                <input id="telText" type="tel" value={tel} onChange={(e) => setTel(e.target.value)} />
              </div>
              <div class="field w-100 pb-5 align-items-center d-flex justify-content-between">
                <span class="mr-md-1">電子信箱</span>
                <input id="emailText" type="email" value={email} onChange={e => setEmail(e.target.value)} />
              </div>
              <div class="field w-100 pb-5 d-flex justify-content-between">
                <span class="mr-md-1 pt-3">詢問內容</span>
                <textarea id="bodyText" name="comments" rows="10" cols="54" value={context} onChange={e => setContext(e.target.value)}></textarea>
              </div>
              <a class="submit" href={`mailto:lyson@lyson.cc?subject=聯絡萊盛&body=${body}`} style={{ width: '100%', maxWidth: '500px', lineHeight: '50px', textAlign: 'center' }}>確認並送出</a>
            </form>
            <div style={{ height: '100%', display: 'flex', alignItems: 'flex-end' }}>
              <p style={{ borderRight: '2px solid black', lineHeight: '120px', paddingRight: '24px' }}>CONTACT</p>
            </div>
          </div>

        </DesktopView>
      </div>
    </div>
  );
};
export default Contact;
