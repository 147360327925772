import styled from "styled-components";
const TitleWrap = styled.section`
  margin: 0 100px 0 0;
  display:block;
  .p {
    margin: 0 0 12px 0;
    padding-left: 6px;
  }
`;
export const MobileView = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
export const DesktopView = styled.div`
  @media screen and (max-width: 767px) {
    display: none;
  }
  @media screen and (min-width: 768px) {
    display:flex;
  }
`;
const Design = () => {
  return (
    <div id="service_design" class="d-inline-block w-100">
      <div>
        <MobileView>
          <div class="w-100" style={{ marginBottom: '92px', padding: '48px 63px' }}>
            <div>
              <p style={{ fontSize: '12px', padding: '8px 0' }}>SERVICE</p>
              <h2 style={{ letterSpacing: '4px' }} class="responsive">
                設計整合
            </h2>
            </div>
            <p style={{ lineHeight: "26px", marginTop: '3rem', fontSize: '18px' }}>
              以整合設計服務的方式，從前端設計服務諮詢、設計執行時的企畫統籌、到後端色彩計畫與材質選配，甚至是現場監工，使設計整合到每個環節，注重每個細節，進而傳達一套有效且正確的資訊或概念給消費者，達到此專案的最佳效果與狀態。
          </p>
          </div>
        </MobileView>
        <DesktopView class="servicedesign" style={{ width: '100%', justifyContent: 'center' }}>
          <div class="d-flex align-items-end" style={{ maxWidth: '1024px', width: '100%', margin: '10rem 0' }}>
            <TitleWrap>
              <p className="p">SERVICE</p>
              <h2 class="responsive" style={{ minWidth: '190px', fontWeight: '900' }}>
                設計<br />整合
              </h2>
            </TitleWrap>
            <p style={{ lineHeight: "26px" }}>
              以整合設計服務的方式，從前端設計服務諮詢、設計執行時的企畫統籌、到後端色彩計畫與材質選配，甚至是現場監工，使設計整合到每個環節，注重每個細節，進而傳達一套有效且正確的資訊或概念給消費者，達到此專案的最佳效果與狀態。
          </p>
          </div>
        </DesktopView>
      </div>
      <img class="w-100" src="/images/blueprint.png" alt="blueprint" />
      <MobileView>
        <div class="w-100 d-flex flex-column justify-content-center" style={{ marginTop: '128px', padding: '12px 51px' }}>
          <div class="w-100 d-flex align-items-end justify-content-center">
            <img src="/images/Vector 6.png" alt="vector 6" />
            <p style={{ position: 'absolute', right: '7rem', color: 'rgba(19, 65, 105, 1)', fontSize: '12px' }}>服務諮詢</p>
          </div>
          <img class="pt-3 pb-3" src="/images/service-m-1.png" alt="service" />
          <p style={{ lineHeight: '23.17px', color: 'rgba(51, 51, 51, 1)' }}>透過談話，深入了解客戶需求與目標，給予適合的建議與方向，若符合客戶想像，專案即可成立。</p>
        </div>

        <div class="w-100 d-flex flex-column justify-content-center" style={{ padding: '12px 51px' }}>
          <div class="w-100 d-flex align-items-end justify-content-center">
            <img src="/images/Vector 4.png" alt="vector 4" />
            <p style={{ position: 'absolute', right: '7rem', color: 'rgba(19, 65, 105, 1)', fontSize: '12px' }}>企劃統籌</p>
          </div>
          <img class="pt-3 pb-3" src="/images/service-m-2.png" alt="service" />
          <p style={{ lineHeight: '23.17px', color: 'rgba(51, 51, 51, 1)' }}>專案成立後，將會項目內容進行安排，例如：從品牌標識著手，再延伸到網頁設計，最後進行到空間規劃，使整體擁有一致性的識別性。</p>
        </div>

        <div class="w-100 d-flex flex-column justify-content-center" style={{ padding: '12px 51px', marginBottom: '78px' }}>
          <div class="w-100 d-flex align-items-end justify-content-center">
            <img src="/images/Vector 4.png" alt="vector 4" />
            <p style={{ position: 'absolute', right: '3.5rem', color: 'rgba(19, 65, 105, 1)', fontSize: '12px' }}>色彩計畫與材質選配</p>
          </div>
          <img class="pt-3 pb-3" src="/images/service-m-3.png" alt="service" />
          <p style={{ lineHeight: '23.17px', color: 'rgba(51, 51, 51, 1)' }}>設計規劃完成時，會搭配材質板、色票給客戶作為輔助參考，確立此專案的風格與特性。</p>
        </div>
      </MobileView>
      <DesktopView>
        <div class="w-100 d-flex justify-content-center p-5">
          <img class="imgservices" src="/images/services.png" alt="services" style={{ width: '100%' }} />
        </div>
      </DesktopView>

      <p style={{ display: "none" }}>
        服務諮詢
        <br />
        透過談話，深入了解客戶需求與目標，給予適合的建議與方向，若符合客戶想像，專案即可成立。
      </p>
      <p style={{ display: "none" }}>
        企畫統籌
        <br />
        專案成立後，將會項目內容進行安排，例如：從品牌標識著手，再延伸到網頁設計，最後進行到空間規劃，使整體擁有一致性的識別性。
      </p>
      <p style={{ display: "none" }}>
        色彩計畫與材質選配
        <br />
        設計規劃完成時，會搭配材質板、色票給客戶作為輔助參考，確立此專案的風格與特性。
      </p>
    </div>
  );
};
export default Design;
