import styled from "styled-components";
import { MobileView, DesktopView } from '../Pages/Design';
const Img = styled.div`
  @media screen and (min-width: 768px) {
    display: block;
  }
  display: none;
`;
export const Section = styled.div`
  width: 100%;
  text-align: right;
  line-height: 80px;
  border-right: 2px solid black;
  padding-right: 1rem;
  margin-top: 2.5rem;
`;
const Scroll = () => {
  return (
    <div class="w-100">
      <DesktopView>
        <div style={{ width: '1420px', display: 'flex', flexDirection: 'column', margin: '400px 0' }}>
          <div style={{ display: 'flex', width: '100%' }}>
            <h2 style={{ letterSpacing: '4px', fontSize: '72px', fontWeight: '900' }}>「 自由&舒活 」</h2>
            <img src="images/icon.png" alt="icon" className="image" />
          </div>
          <p style={{ fontSize: '16px', letterSpacing: '1px', lineHeight: '23.17px', marginTop: '32px', padding: '0 0 3rem 3rem' }}>
            將設計融入生活，將生活掌在手中，
            <br />
            進而達成雙向互惠的關係。
          </p>
          <Section>SCROLL</Section>
        </div>
      </DesktopView>
      <MobileView>
        <div class="flex-column d-inline-block vh-100 w-100 d-flex" style={{ paddingTop: '220px' }} >
          <div>
            <h2 class="responsive" style={{ letterSpacing: '4px', fontSize: '38px' }}>「 自由&舒活 」</h2>
            <Img>
              <img src="images/icon.png" alt="icon" className="image" />
            </Img>
          </div>
          <p style={{ fontSize: '16px', lineHeight: '23.17px', letterSpacing: '1px', padding: '23px 0' }}>
            將設計融入生活，將生活掌在手中，進而達成雙向互惠的關係。
          </p>
          <div style={{ paddingRight: '24px', marginTop: '214px', width: '100%', textAlign: 'right', lineHeight: '80px', borderRight: '2px solid black' }}>
            SCROLL
          </div>
        </div>
      </MobileView>

    </div>
  );
};
export default Scroll;
