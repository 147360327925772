import { useState } from "react";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
const Drawer = styled.ul`
  width: 146px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #b9d9c0;
  opacity: 0%;
  position: absolute;
  top: 45px;
`;
const DrawerM = styled.ul`
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #b9d9c0;
  bottom:-3px;
  display: ${(props) => (props.display ? "flex" : "none")};
`;
const Button = styled.li`
  width: 120px;
  height: 46px;
  border-bottom: 1px solid #fff;
  text-align: center;
  line-height: 46px;
  .a {
    text-decoration: none;
    color: black;
  }
`;
const Wrap = styled.div`
  @media screen and (min-width: 999px) {
    padding: 8rem 4rem 6rem;
  }
  @media screen and (min-width: 768px) {
    padding: 8rem 2rem 6rem;
    .img_m {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 2rem;
    .img {
      display: none;
    }
    .img_m {
      display: block;
    }
  }
  width: 100%;
  max-width: 1600px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  .img {
    width: 255px;
  }
`;
const Tab = styled.div`
  height: 46px;
  line-height: 46px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 1rem;
  text-decoration: none;
  font-weight: 700;
  color: black;
  .drawer {
    opacity: 0;
  }
  &:hover ${Drawer} {
    opacity: 100%;
  }
`;
const Tabs = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CloseIc = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 3rem;
  .close {
    width: 38px;
  }
`;
const Menu = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
  width: 240px;
  height: 100%;
  background-color: #fff;
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  margin: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
`;
const Img = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
  display: block;
`;
const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: ${(props) => (props.opacity ? "block" : "none")};
  position: fixed;
  z-index: 98;
  backdrop-filter: blur(5px);
`;
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [display, setDisplay] = useState(false);
  const handleClose = () => {
    setDisplay(false);
    setIsOpen(false);
  };
  return (
    <>
      <Wrap>
        <Link to="/">
          <img src="/images/logo.png" alt="logo" className="img" />
          <img src="/images/logo_m.png" alt="logo" className="img_m" />
        </Link>
        <Tabs>
          <Tab>
            <Link to="/#about_d" className="a">
              關於我們
            </Link>
          </Tab>
          <Tab>
            服務項目
            <Drawer className="drawer">
              <Button>
                <Link to="/services/design" className="a">
                  設計整合
                </Link>
              </Button>
              <Button>
                <Link to="/services/tech" className="a">
                  資訊通訊科技
                </Link>
              </Button>
            </Drawer>
          </Tab>
          <Tab className="a">
            <Link to="/kanbellab">KANBELLAB總代理</Link>
          </Tab>
          <Tab className="a">
            <Link to="/#CONTACT">聯絡我們</Link>
          </Tab>
        </Tabs>
        <Img>
          <img
            src="/images/menu.png"
            alt="menu"
            onClick={() => setIsOpen(true)}
          />
        </Img>
        <Menu open={isOpen}>
          <CloseIc>
            <img
              src="/images/close.png"
              alt="close"
              className="close"
              onClick={() => handleClose()}
            />
          </CloseIc>
          <Tab onClick={() => handleClose()}>
            <Link to="/#about" className="a">
              關於我們
            </Link>
          </Tab>
          <Tab onClick={() => setDisplay((prev) => !prev)}>服務項目</Tab>
          <DrawerM display={display}>
            <Button onClick={() => handleClose()}>
              <Link to="/services/design" className="a">
                設計整合
              </Link>
            </Button>
            <Button onClick={() => handleClose()}>
              <Link to="/services/tech" className="a">
                資訊通訊科技
              </Link>
            </Button>
          </DrawerM>
          <Tab onClick={() => handleClose()}>
            <Link to="/kanbellab">KANBELLAB總代理</Link>
          </Tab>
          <Tab onClick={() => handleClose()}>
            <Link to="/#CONTACT">聯絡我們</Link>
          </Tab>
        </Menu>
      </Wrap>
      <Background opacity={isOpen}></Background>
    </>
  );
};
export default Header;
