import styled from "styled-components";
import Scroll from "../component/Scroll";
import About from "../component/About";
import Contact from "../component/Contact";
const Wrap = styled.div`
  @media screen and (min-width: 768px) {
    padding: 0 4rem 0 8rem;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    padding: 0 2rem;
    width: 100%;
  }
  width: 100%;
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .img {
    width: 180px;
  }
`;
const Home = () => {
  return (
    <Wrap>
      <Scroll />
      <About />
      <Contact />
    </Wrap>
  );
};
export default Home;
