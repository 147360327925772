import styled from "styled-components";
const TitleWrap = styled.div`
  @media screen and (min-width: 1400px) {
    margin-left: 20rem;
  }
  @media screen and (min-width: 1000px) {
    margin-left: -10rem;
  }
  @media screen and (max-width: 767px) {
    margin: 2rem 0;
  }
  margin: 15rem 0 10rem 0;
  max-width: 680px;
  .service {
    width: 100%;
    margin: 0 0 12px 0;
  }
  .service_title {
    margin-bottom: 52px;
  }
`;
const MobileView = styled.div`
@media screen and (min-width: 768px) {
    display: none;
  }
  @media screen and (max-width: 767px) {
    display: flex;
  }
`;
const DesktopView = styled.div`
  @media screen and (max-width: 767px) {
    display: none;
  }
  @media screen and (min-width: 768px) {
  display:flex;
  }
`;
const Tech = () => {
  return (
    <div class="w-100 d-flex justify-content-center flex-column">
      <MobileView>
        <div class="w-100" style={{ marginBottom: '6px', padding: '48px 63px' }}>
          <div>
            <p style={{ fontSize: '12px', padding: '8px 0' }}>SERVICE</p>
            <h2 style={{ letterSpacing: '3px', fontSize: '38px', lineHeight: '48px' }} class="responsive">
              資訊通訊科技
            </h2>
          </div>
          <p style={{ marginTop: '3rem', fontSize: '16px', lineHeight: '23.17px', letterSpacing: '1px' }}>
            身處科技時代，人們每日大量地使用網絡、無線通訊、大量數據與分析，倘若由IOT搭配AIOT，使企業擁有一套符合自己需求的資訊與通訊設備系統是非常有利的，透過人工智慧的技術，使機器從數據資料庫中學習，將能做出預測性的分析，找出潛在風險與企業價值。
            <br />
            <br />
            對外，能優化消費者的體驗及信賴度，同時，對內也能提升效率、智慧庫存管理，擁有穩定性與安全性，降低外在的風險。
          </p>
        </div>
      </MobileView>
      <MobileView>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '6rem' }}>
            <img src="/images/dash.png" alt="dash" style={{ paddingLeft: '3rem', marginTop: '5rem' }} />
            <img src="/images/bgimage_man.png" alt="bgimage_man" style={{ width: '50%', marginBottom: '5rem' }} />
          </div>

          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', maxWidth: '500px' }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
              <img src="/images/idea_m_1.png" alt="idea" style={{ width: '100%', paddingLeft: '1rem', maxWidth: '320px' }} />
            </div>
            <p style={{ width: '100%', padding: '2rem', lineHeight: '23px' }}>透過建置機房，能使您的伺服器、網路設備、交換機更為穩定運行，提高效能處理網路上的訊息、數據以及溝通。</p>
          </div>
          <div style={{ width: '100%', margin: '2rem 0', maxWidth: '500px' }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
              <img src="/images/idea_m_2.png" alt="idea" style={{ width: '100%', paddingRight: '1rem', maxWidth: '320px' }} />
            </div>
            <p style={{ width: '100%', padding: '2rem', lineHeight: '23px' }}>藉由大量的數據找到潛在的客源，進一步準確分析客戶需求；甚至是先從海量的數據進行篩選，針對有效數據去深入挖掘與運用，精準提供企業成功方案，為您降低成本。</p>
          </div>
          <div style={{ width: '100%', maxWidth: '500px' }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
              <img src="/images/idea_m_3.png" alt="idea" style={{ width: '100%', paddingLeft: '1rem', maxWidth: '320px' }} />
            </div>
            <p style={{ width: '100%', padding: '2rem', lineHeight: '23px' }}>AIOT是人工智能技術與物聯網（IOT）的結合，是一種新的IOT應用型態，落實真正的智能生態。</p>
          </div>
        </div>

      </MobileView>
      <DesktopView>
        <div class="w-100 d-flex justify-content-center flex-column" style={{ position: 'relative' }}>
          <div id="service_tech" class="w-100 d-flex flex-column justify-content-center align-items-center">
            <TitleWrap>
              <p className="service">SERVICE</p>
              <h2 className="service_title" style={{ fontWeight: '900' }}>資訊通訊科技</h2>
              <p style={{ lineHeight: "26px", letterSpacing: '1px' }}>
                身處科技時代，人們每日大量地使用網絡、無線通訊、大量數據與分析，倘若由IOT搭配AIOT，使企業擁有一套符合自己需求的資訊與通訊設備系統是非常有利的，透過人工智慧的技術，使機器從數據資料庫中學習，將能做出預測性的分析，找出潛在風險與企業價值。
            <br />
                <br />
            對外，能優化消費者的體驗及信賴度，同時，對內也能提升效率、智慧庫存管理，擁有穩定性與安全性，降低外在的風險。
          </p>
            </TitleWrap>
          </div>
        </div>
      </DesktopView>
      <DesktopView>
        <div class="bgimgs">
          <img class="bgman" src="/images/bgimage_man.png" alt="bgimage_man" />
          {/* <img class="bgidea" src="/images/idea.png" alt="idea" /> */}
          <div class="bgidea" />
        </div>
      </DesktopView>
      <div style={{ display: "none" }}>
        <p>
          透過建置機房，能使您的伺服器、網路設備、交換機更為穩定運行，提高效能處理網路上的訊息、數據以及溝通。
        </p>
        <p>
          藉由大量的數據找到潛在的客源，進一步準確分析客戶需求；甚至是先從海量的數據進行篩選，針對有效數據去深入挖掘與運用，精準提供企業成功方案，為您降低成本。
        </p>
        <p>
          AIOT是人工智能技術與物聯網（IOT）的結合，是一種新的IOT應用型態，落實真正的智能生態。
        </p>
      </div>
    </div>
  );
};
export default Tech;
