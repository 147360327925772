import styled from "styled-components";
const Wrap = styled.a`
@media screen and (min-width: 768px) {
  display: none;
}
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top:3rem;
`;
const Img = styled.div`
  width: 40px;
  height: 40px;
  display: block;
  background: #333333;
  bottom: 0;
  right: 0;
  display:fixed;
  align-items: center;
  justify-content: center;
`;
const Top = () => {
  return (
    <Wrap href="#home">
      <p style={{ borderBottom: "1px solid black", lineHeight: "22px", fontSize: '12px', marginRight: '10px' }}>
        BACK TO TOP
      </p>
      <Img>
        <img src="/images/top.png" alt="top" />
      </Img>
    </Wrap>
  );
};
export default Top;
